
import { locale } from './globals.js';

class HomeMedia {
  constructor(el) {
    if(!el) return false;
    this.el = el;
    this.rtl = (locale.dir == 'rtl');
    this.initSlider();
  }
  initSlider () {
    this.slider = new Swiper('.home-media-slider', {
      slidesPerView: 1,
      speed: 500,
      spaceBetween: 2,
      // autoplay: {
      //   delay: 7000
      // },
      navigation: {
        nextEl: '.hero-pager-button.pager-next',
        prevEl: '.hero-pager-button.pager-prev',
      },
      breakpoints: {
        740: {
          slidesPerView: 2
        },
        1100: {
          slidesPerView: 3
        }
      }
    })
    
  }
  
}
new HomeMedia(document.querySelector('[data-home-media]'));
