

export const locale = {
  lang: document.querySelector('html').getAttribute('lang'),
  dir: document.querySelector('html').getAttribute('dir'),
  next: (document.querySelector('html').getAttribute('dir') == 'ltr') ? 'right' : 'left',
  prev: (document.querySelector('html').getAttribute('dir') == 'ltr') ? 'left' : 'right'
};

export const root = document.querySelector('body').getAttribute('data-root');
