
import './modules/smooth-anchors';
import './modules/nav';
import './modules/search-box';
import './modules/home-hero';
import './modules/home-media';
import './modules/form-handler';
import './modules/tabs';
import './modules/countdown';
import './modules/instagram-feed';


import { locale } from './modules/globals.js';
try {
  if (moment) {
    if (locale.dir == "ltr") {
      moment.locale('en-gb');
    } else {
      moment.locale('ar-ae');
    }
  }
} catch {}


