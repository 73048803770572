
import { locale } from './globals.js';

class HomeHero {
  constructor(el) {
    if(!el) return false;
    this.el = el;
    this.rtl = (locale.dir == 'rtl');
    this.initSlider();
  }
  initSlider () {
    this.slider = new Swiper('.hero-slider', {
      slidesPerView: 1,
      speed: 800,
      autoplay: {
        delay: 7000
      },
      navigation: {
        nextEl: '.hero-pager-button.pager-next',
        prevEl: '.hero-pager-button.pager-prev',
      },
      on: {
        init: function() {
          let video = this.slides[this.activeIndex].querySelector('video')
          if (video) video.play()
        }
      }
    })
    this.slider.on('slideChange', function () {
      Array.from(this.slides).forEach((slide, index) => {
        let video = slide.querySelector('video');
        if (!video) return;
        if(index == this.activeIndex) {
          video.play()
        } else {
          video.pause()
        }
      });
    });
  }
  
}
new HomeHero(document.querySelector('[data-home-hero]'));
