
import { locale } from './globals.js';
import { getOffset, debounce, viewport } from './toolbox.js';


class Tabs {
  constructor (el) {
    if(!el) return false;
    this.tabList = el;
    this.current = 0;
    this.parentSlide = this.tabList.closest('.carousel-item');
    
    this.initItems();
    this.activateIndex();
    window.addEventListener('hashchange', (event) => {
      this.checkHash();
    })
    if(this.parentSlide) {
      $(this.parentSlide).on('active.carousel', (event) => {
        this.updateHash();
      })
    }
    this.checkHash();
  }
  checkView () {
    this.activateIndex();
  }
  checkHash (hash) {
    let index = -1;
    if(hash == undefined) hash = location.hash;
    this.tabs.forEach((tab, i) => {
      if(hash == tab.getAttribute('href')) {
        index = i;
      }
    })
    if(index > -1) {
      this.activateIndex(index);
      if(this.parentSlide) {
        $(this.parentSlide).trigger('initslide.carousel');
      }
    }
  }
  activateIndex (target) {
    if(target == undefined) {
      target = this.current;
    }
    this.tabs.forEach((tab, i) => {
      if(target == i) {
        this.current = target;
        this.tabs[i].classList.add('active');
        this.tabs[i].closest('li').classList.add('active');
        this.contents[i].classList.add('active');
      } else {
        this.tabs[i].classList.remove('active');
        this.contents[i].classList.remove('active');
        this.tabs[i].closest('li').classList.remove('active');
      }
    });
  }
  // scrollToHeader () {
  //   $('html, body').animate({scrollTop: getOffset(this.accordionTabs[this.current]).top - 80}, 350, 'swing', () => {
  //     this.updateAccordion();
  //   });
  // }
  initItems (state) {
    this.tabs = Array.from(this.tabList.querySelectorAll('a[href^="#"]'));
    this.contents = [];
    this.tabs.forEach((tab, i) => {
      let index = i;
      this.contents[i] = document.querySelector(tab.getAttribute('href'));
      this.contents[i].classList.add('tab-content');
      if (tab.classList.contains('active')) this.current = i;
      tab.addEventListener('click', (event) => {
        event.preventDefault();
        this.activateIndex(index);
        this.updateHash();
      });
    })
    this.contentList = this.contents[0].parentElement;
    this.contentList.classList.add('tab-content-list');
  }
  updateHash (index) {
    if (index == undefined) index = this.current;
    let hash = this.tabs[index].getAttribute('href');
    if (location.hash == hash) {
      return;
    }
    if(history && history.pushState) {
      history.pushState(null, null, hash);
    } else {
      location.hash = hash;
    }
  }
}

var widgets = Array.from(document.querySelectorAll('[data-tabs]'));
if (widgets.length > 0) {
  widgets.forEach(tabs => {
    new Tabs(tabs);
  })
}
