

class SearchBox {
  constructor(el) {
    if(!el) return false;
    this.el = el;
    this.button = this.el.querySelector('button');
    this.input = this.el.querySelector('input');
    this.events();
    this.setState('ready');
  }
  events() {
    this.button.addEventListener('click', this.clickHandler.bind(this));
    this.input.addEventListener('blur', this.blurHandler.bind(this));
  }
  clickHandler(event) {
    if(this.state == 'ready') {
      event.preventDefault();
      this.setState('search');
    } else if(this.state == 'search') {
      this.el.submit();
    }
  }
  blurHandler(event) {
    setTimeout(() => {
      if(this.state == 'search') {
        this.setState('ready');
        this.input.blur();
      }
    }, 300);
  }
  setState(state) {
    this.state = state;
    this.el.setAttribute('data-state', state);
    if(this.state == 'search') {
      this.input.focus();
      document.querySelector('html').classList.add('search-mode');
    } else if(this.state == 'ready') {
      document.querySelector('html').classList.remove('search-mode');
    }
  }
}

new SearchBox(document.querySelector('[data-search-box]'));

