let html = document.querySelector('html');

function toggle() {
  html.classList.toggle('show-nav');
}
function close() {
  html.classList.remove('show-nav');
}
function open() {
  html.classList.add('show-nav');
}

Array.from(document.querySelectorAll('[data-nav-toggle]')).forEach((elem) => {
  elem.addEventListener('click', toggle)
})
Array.from(document.querySelectorAll('[data-nav-close]')).forEach((elem) => {
  elem.addEventListener('click', close)
})