
import { locale } from './globals.js';
import { loadJSON, throttle, truncateString } from './toolbox.js';

const defaultApi = '/data/instagram.json';

const postElem = function (id, index) {
  let item = document.createElement('div');
  item.classList.add('post-item', 'swiper-slide');

  item.innerHTML = `
    <div class="post-thumb media aspect-ratio cover square" id="${id}" data-index="${index}">
      <img src="/assets/media/social/${id}.jpg" alt>
    </div>
  `
  return item;
}
const mediaUrl = function (id, size = 'l') {
  return `https://api.instagram.com/p/${id}/media?size=${size}`
}
const postUrl = function (id) {
  return `https://www.instagram.com/p/${id}/`
}
const postEmbedUrl = function (id) {
  return `https://api.instagram.com/oembed?url=http://instagr.am/p/${id}/`
}
const captionTease = function (id, caption) {
  let read_more = {
    en: "Read More",
    ar: "اقرأ المزيد"
  }
  return `<a href="${postUrl(id)}" target="_blank" class="post-caption"><span class="copy">${truncateString(caption, 300)}</span><br><span class="cta">${read_more[locale.lang]}</span></a>`
}
class SocialMediaThumbs {
  constructor (el) {
    if(!el) return false;
    this.el = el;
    this.api = this.el.getAttribute('data-api') ? this.el.getAttribute('data-api') : defaultApi;
    this.rowLength = parseInt(window.getComputedStyle( this.el, ':before').getPropertyValue('content').split('"')[1]);
    this.expandIndex = 1;
    this.batch = this.rowLength * 2
    loadJSON(this.api, (data) => {
      this.data = JSON.parse(data);
      
      requestAnimationFrame(() => {
        this.createPosts();
        // console.log(this.posts);
        this.renderList();
        setTimeout(() => {
          this.initSlider() 
        }, 600)
      })
    }, (resp) => {});
  }
  createPosts() {
    this.posts = [];
    this.data.forEach((id, index) => {
      this.posts.push(postElem(id, index));
    })
    //this.loadCaptions();
  }
  initSlider() {
    console.log(this.slider)
    this.swiper = new Swiper(this.slider, {
      slidesPerView: 'auto',
      spaceBetween: 2,
      freeMode: true,
      // freeModeMomentum: true,
      navigation: {
        nextEl: this.pagerNext,
        prevEl: this.pagerPrev
      }
    })
  }
  renderList() {
    this.slider = document.createElement('div')
    this.slider.classList.add('post-slider', 'swiper-container')

    this.list = document.createElement('div')
    this.list.classList.add('post-list', 'swiper-wrapper')

    this.pagerNext = document.createElement('button')
    this.pagerNext.classList.add('pager-button', 'pager-next')
    this.pagerNext.innerHTML = `<i class="fas icon fa-arrow-${locale.next}"></i>`
    
    this.pagerPrev = document.createElement('button')
    this.pagerPrev.classList.add('pager-button', 'pager-prev')
    this.pagerPrev.innerHTML = `<i class="fas icon fa-arrow-${locale.prev}"></i>`
    
    this.slider.appendChild(this.list)
    this.slider.appendChild(this.pagerPrev)
    this.slider.appendChild(this.pagerNext)

    this.posts.forEach((post, index) => {
      this.list.appendChild(post);
    })

    requestAnimationFrame(() => {
      this.el.appendChild(this.slider)
    })
  }
  loadCaptions() {
    this.posts.forEach((post, index) => {
      loadJSON(postEmbedUrl(this.data[index]), (data) => {
        let postData = JSON.parse(data);
        requestAnimationFrame(() => {
          this.renderCaption(this.data[index], post, postData);
        })
      }, (resp) => {});
    })
  }
  renderCaption(id, post, data) {
    let thumb = post.querySelector('.post-thumb');
    // post.setAttribute('title', data.title);
    // console.log(data);
    post.innerHTML += captionTease(id, data.title)
  }
  
}
new SocialMediaThumbs(document.querySelector('[data-instagram-feed]'));
