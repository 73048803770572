import {
	initValidation,
	validate
} from './form-validator';
import {
	insertAfter
} from './toolbox'

const forms = [];
const starring = ['tel', 'text', 'email', 'date']

function SmartForm(el) {
	this.el = el;
	this.init();
};
SmartForm.prototype.init = function () {
	this.id = `f${forms.length-1}`
	this.el.setAttribute('novalidate', '');
	this.el.setAttribute('data-state', 'ready');
	this.requireds = Array.from(this.el.querySelectorAll('[required]'))
	this.validees = Array.from(this.el.querySelectorAll('[data-validate]'))
	this.fileInputs = Array.from(this.el.querySelectorAll('[data-file]'))
	this.selects = Array.from(this.el.querySelectorAll('select'))

	if (this.el.hasAttribute('data-api')) {
		this.el.addEventListener('submit', this.submitHandler.bind(this));
	}
	this.requireds.forEach((elem, index) => {
		initValidation(elem);
		this.markFields(elem)
	});
	this.validees.forEach((elem, index) => {
		initValidation(elem);
	});

	this.dependants = Array.from(this.el.querySelectorAll('[data-requires]'))
	this.dependants.forEach((elem) => {
		this.initDependant(elem)
	})
	this.selects.forEach((elem) => {
		this.initSelect(elem)
	})
	// console.log(this.dependants)

	this.fileInputs.forEach((elem) => {
		this.initFileInputs(elem)
	})
}
SmartForm.prototype.initSelect = function (elem) {
	elem.addEventListener('change', update)
	update()
	function update() {
		if (elem.value === 'n/a') {
			elem.setAttribute('data-selected', 'false');
		} else {
			elem.setAttribute('data-selected', true);
		}
	}
}
SmartForm.prototype.markFields = function (elem) {
	if ((elem.tagName === 'INPUT' && starring.includes(elem.getAttribute('type')) || elem.tagName === 'SELECT' || elem.tagName === 'TEXTAREA')) {
		var star = document.createElement('span')
		star.classList.add('star')
		star.innerHTML = '*'
		insertAfter(star, elem)
	}
}
SmartForm.prototype.initFileInputs = function (elem) {
	var input = elem.querySelector('input[type="file"]');
	var name = elem.querySelector('[data-name]');
	var size = elem.querySelector('[data-size]');
	input.addEventListener('change', updateInput);

	function updateInput() {
		validate(input)
		if (input.files.length == 0) return;
		name.innerHTML = input.files[0].name
		size.innerHTML = returnFileSize(input.files[0].size)
	}

	function returnFileSize(number) {
		if (number < 1024) {
			return number + 'bytes';
		} else if (number >= 1024 && number < 1048576) {
			return (number / 1024).toFixed(1) + 'KB';
		} else if (number >= 1048576) {
			return (number / 1048576).toFixed(1) + 'MB';
		}
	}
}
SmartForm.prototype.initDependant = function (elem) {
	var requirement = this.el.querySelector(`[name="${elem.getAttribute('data-requires')}"`);
	var vanishing = (elem.hasAttribute('data-vanishing')) ? elem.closest('.field-wrap') || elem.closest('.checkbox-wrap') || elem : null;
	if (!requirement) return;
	updateElem()
	requirement.addEventListener('change', (event) => {
		// console.log(requirement)
		updateElem()
	})

	function updateElem() {
		var disabled = !validate(requirement, true);
		elem.disabled = disabled;
		if (disabled) {
			elem.classList.add('disabled')
			elem.setAttribute('data-state', 'ready')
			// elem.value = null
		} else {
			elem.classList.remove('disabled')
			if (elem.hasAttribute('data-requires-value') && elem.hasAttribute('data-api')) {
				elem.setAttribute('href', `${elem.getAttribute('data-api')}${requirement.value}`)
			}
		}
		if (vanishing) {
			if (disabled) {
				vanishing.classList.add('hidden')
			} else {
				vanishing.classList.remove('hidden')
			}
		}
	}
}
SmartForm.prototype.validateForm = function () {
	var valid = true;
	this.requireds.forEach((elem) => {
		validate(elem);
		if (elem.getAttribute('data-state') === 'error') valid = false;
	})
	return valid;
}
SmartForm.prototype.submitHandler = function (event) {
	event.preventDefault();
	event.stopPropagation();

	if (!this.validateForm()) return;

	// get api url
	this.api = this.el.getAttribute('data-api');

	this.setState('working');
	if (this.responseBox) this.responseBox.remove();

	if (this.api.length == 0 || this.api == 'test') {
		var testState = (this.el.hasAttribute('data-test-state')) ? this.el.getAttribute('data-test-state') : 'error';
		setTimeout(function () {
			this.setState(testState);
		}.bind(this), 1000);
		return;
	}
	this.submitData();
}
SmartForm.prototype.submitData = function () {
	var XHR = new XMLHttpRequest();
	var FD = new FormData(this.el);
	console.log(this.el, FD)
	// Success
	XHR.addEventListener('load', (event) => {
		this.response = JSON.parse(event.target.responseText);
		if (event.target.status == 200) {
			this.setState('success');
		} else {
			this.setState('error');
		}
	});
	XHR.open("POST", this.api);
	XHR.send(FD);
}
SmartForm.prototype.setState = function (state) {
	this.el.setAttribute('data-state', state);
	this.state = state;
	if (state == 'error') {
		this.displayResponse();
		requestAnimationFrame(() => {
			this.setState('error-response');
		})
		setTimeout(function () {
			this.el.setAttribute('data-state', 'ready');
			if (this.responseBox) this.responseBox.remove();
		}.bind(this), 4000);
	}
	if (state == 'success') {
		setTimeout(function () {
			if (this.el.hasAttribute('data-redirect')) {
				window.location.pathname = this.el.getAttribute('data-redirect');
			}
			if (this.el.hasAttribute('data-collapse')) {
				this.el.classList.add('collapse');
			}
			this.displayResponse();
			requestAnimationFrame(() => {
				this.setState('success-response');
			})
			setTimeout(function () {
				this.el.setAttribute('data-state', 'ready');
				if (this.responseBox) this.responseBox.remove();
			}.bind(this), 5000);
		}.bind(this), 1000);
	}
}
SmartForm.prototype.displayResponse = function () {
	if (!window.location.pathname.includes('awards')) return;
	if (this.response == undefined) {
		if (this.state == 'success') {
			this.response = {
				message: (this.el.getAttribute('data-success-response')) ? this.el.getAttribute('data-success-response') : 'Thank you!'
			}
		}
		if (this.state == 'error') {
			this.response = {
				message: (this.el.getAttribute('data-error-response')) ? this.el.getAttribute('data-error-response') : 'Error!'
			}
		}

	}
	this.responseBox = document.createElement('div');
	this.responseBox.classList.add('response-box');
	this.responseBox.innerHTML = this.response.message;
	this.el.parentNode.insertBefore(this.responseBox, this.el.nextSibling);
}

export function initForms() {
	Array.from(document.querySelectorAll('[data-smart-form]')).forEach(function (elem, index) {
		init(elem);
	});
};
export function init(elem) {
	if (elem.hasAttribute('data-state')) return;
	forms.push(elem)
	var form = new SmartForm(elem);
}

initForms();
// export default function (elem) {
// 	if (!elem || elem.length < 1) {
// 		initAll();
// 	} else {
// 	  init(elem);
// 	}
// }