
import { locale } from './globals.js';

const fieldErrors = {
	required: {
		'en': 'This field is required.',
		'ar': 'هذا الحقل إلزامي'
	},
	requiredSelect: {
		'en': 'Please select.',
		'ar': 'الرجاء اختيار'
	},
	email: {
		'en': 'Please enter a valid email address.',
		'ar': 'رجاء إدخال عنوان بريد إلكتروني صحيح'
	},
	phone: {
		'en': 'Please enter a valid phone number',
		'ar': 'صحيح  يرجى إدخال رقم هاتف'
	},
	emiratesID: {
		'en': 'Please enter a valid Emirate ID number',
		'ar': 'يرجى إدخال رقم الهوية الإماراتية'
	},
	sizeBig: {
		'en': 'File is too big.',
		'ar': 'File is too big.'
	}
}
function errorLabel () {
  var label = document.createElement('div');
  label.classList.add('error-msg');
  return label;
}
function init_intlTelInput () {
  let style = document.createElement('link');
  style.setAttribute('rel', 'stylesheet');
  style.setAttribute('href', '/assets/lib/intl-tel-input/css/intlTelInput.min.css');
  document.querySelector('head').append(style);

  let script = document.createElement('script');
  script.setAttribute('src', '/assets/lib/intl-tel-input/js/intlTelInput.min.js')
  script.addEventListener('load', (event) => {
    init_intlTelInput_fields();
  })
  document.querySelector('body').append(script);
}
function init_intlTelInput_fields () {
  intlTelInputGlobals.loadUtils('/assets/lib/intl-tel-input/js/utils.js');

  let telFields = Array.from(document.querySelectorAll('input[type="tel"]'));

  telFields.forEach((elem) => {
    let name = elem.getAttribute('name')
    let iti = intlTelInput(elem, {
      hiddenInput: '' + name + '_int',
      preferredCountries: ['ae','kw','sa','bh','om','qa'],
      excludeCountries: ['il'],
      separateDialCode: true,
      formatOnDisplay: true,
      autoPlaceholder: 'aggressive',
      nationalMode: true
    })
    
    elem.parentNode.appendChild(errorLabel());
    elem.setAttribute('data-state', 'ready');
    elem.addEventListener('change', update)
  })
  function update(event) {
    var field = this;
    var fieldInt = field.nextSibling;
    if (!fieldInt) return;
    var iti = window.intlTelInputGlobals.getInstance(field)
    fieldInt.value = iti.getNumber();
  }
}
function fieldUpdate (elem, errorID) {
  let errorDom = elem.parentNode.querySelector('.error-msg');
  if(elem.type === 'tel' && elem.hasAttribute('data-intl-tel-input-id'))
    if(errorDom) errorDom = elem.parentNode.parentNode.querySelector('.error-msg')

  if(errorID) {
    elem.setAttribute('data-state', 'error');
    if(errorDom) {
      errorDom.innerHTML = fieldErrors[errorID][locale.lang];
      errorDom.classList.add('show');
    }
  } else {
    elem.setAttribute('data-state', 'valid');
    if(errorDom) errorDom.classList.remove('show');
  }
}

export function validateInput (elem, quite) {
  if(elem.disabled) return;
  if(elem.value == '') {
    if (elem.hasAttribute('required')) {
      if(!quite) fieldUpdate(elem, 'required');
      return false
    } else {
      if(!quite) fieldUpdate(elem);
      elem.setAttribute('data-state', 'ready');
      return true
    }
	} else {
		if(elem.type === 'email') {
			if (validateEmail(elem.value)) {
        if(!quite) fieldUpdate(elem);
        return true
			} else {
        if(!quite) fieldUpdate(elem, 'email');
        return false
			}
		} else if(elem.type === 'checkbox') {
			if (elem.checked) {
        if(!quite) fieldUpdate(elem);
        return true
			} else {
        if(!quite) fieldUpdate(elem, 'required');
        return false
			}
		} else if(elem.type === 'tel') {
      let tElem = window.intlTelInputGlobals.getInstance(elem);
      if(tElem.isValidNumber()) {
        if(!quite) fieldUpdate(elem);
        return true
      } else {
        if(!quite) fieldUpdate(elem, 'phone');
        return false
      }
		} else if(elem.type === 'file') {
      var sizeMax = parseInt(elem.getAttribute('data-size-max'))
      if(sizeMax) {
        if (elem.files[0].size < sizeMax) {
          if(!quite) fieldUpdate(elem);
          return true;
        } else {
          if(!quite) fieldUpdate(elem, 'sizeBig');
          return false
        }
      }
    } else {
      fieldUpdate(elem);
      return true
		}
	}
};
export function validateSelect (elem, quite) {
  if(elem.disabled) return;
  if (elem.value == 'n/a') {
		if(!quite) {
      elem.setAttribute('data-state', 'error');
      var errorWrap = elem.parentNode.querySelector('.error-msg')
      if (errorWrap) errorWrap.innerHTML = fieldErrors.requiredSelect[locale.lang];
    }
    return false;
	} else {
    if(!quite) elem.setAttribute('data-state', 'valid');
    return true;
	}
};
export function validateEmail (email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export function validate (elem, quite) {
  var valid = undefined;
	if(elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
		valid = validateInput(elem, quite);
	}
	if(elem.tagName === 'SELECT') {
		valid = validateSelect(elem, quite);
	}
	return valid
};

export function initValidation (elem) {
  if(elem.tagName === 'INPUT' && elem.type === 'tel') {
    try { intlTelInput }
    catch {
      if (!window.itiLoaded) {
        init_intlTelInput()
        window.itiLoaded = true
      }
    }
  } else {
    elem.parentNode.appendChild(errorLabel());
    elem.setAttribute('data-state', 'ready');
  }

  elem.addEventListener('blur', (event) => {
    if(elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') {
      validateInput(elem);
    }
  });
  elem.addEventListener('input', (event) => {
    if(elem.tagName === 'SELECT') {
      validateSelect(elem);
    }
    if((elem.tagName === 'INPUT' || elem.tagName === 'TEXTAREA') && elem.getAttribute('data-state') === 'error') {
      validateInput(elem);
    }
    if(elem.tagName === 'INPUT' && elem.type === 'checkbox') {
      validateInput(elem);
    }
    if(elem.tagName === 'INPUT' && elem.type === 'tel') {
      validateInput(elem);
    }
  });
};

