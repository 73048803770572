

class Countdown {
  constructor(el) {
    this.el = el;
    this.eventDate = new Date($(this.el).attr('data-date'))
    this.eventTime = moment(this.eventDate.getTime()).unix()
    this.currentTime = moment(new Date().getTime()).unix()
    this.diffTime = this.eventTime - this.currentTime
    this.duration = moment.duration(this.diffTime * 1000, 'milliseconds')
    this.tick = 1000
    this.display = {
      month: $('<div class="month unit">0</div>'),
      day: $('<div class="day unit">0</div>'),
      hour: $('<div class="hour unit">0</div>'),
      min: $('<div class="min unit">0</div>'),
      sec: $('<div class="sec unit">0</div>')
    }

    $(this.el).append(this.display.month).append(this.display.day).append(this.display.hour).append(this.display.min).append(this.display.sec);

    if(this.diffTime > 0) {
      clearInterval(this.interval);
      this.interval = setInterval(this.interval.bind(this), this.tick);
    }
  }
  interval () {
    this.duration = moment.duration(this.duration.asMilliseconds() - this.tick, 'milliseconds');
    var mo = moment.duration(this.duration).months(),
        d = moment.duration(this.duration).days(),
        h = moment.duration(this.duration).hours(),
        m = moment.duration(this.duration).minutes(),
        s = moment.duration(this.duration).seconds();
  
    // mo = $.trim(m).length === 1 ? '0' + m : m;
    // d = $.trim(d).length === 1 ? '0' + d : d;
    h = $.trim(h).length === 1 ? '0' + h : h;
    m = $.trim(m).length === 1 ? '0' + m : m;
    s = $.trim(s).length === 1 ? '0' + s : s;
  
    // show how many hours, minutes and seconds are left
    window.requestAnimationFrame(function(){
      this.display.month.text(mo);
      this.display.day.text(d);
      this.display.hour.text(h);
      this.display.min.text(m);
      this.display.sec.text(s);
    }.bind(this));
  }
}

export function init(elem) {
	var o = new Countdown(elem);
}

Array.from(document.querySelectorAll('[data-countdown]')).forEach(function (elem) {
  init(elem);
});