export function debounce (fn, delay) {
	var timer = null;
	return function () {
		var context = this, args = arguments;
		// clearTimeout(timer);
		// timer = setTimeout(function () {
		// 	fn.apply(context, args);
		// }, delay);
		if (Date.now() - timer > delay) {
      fn.apply(context, args);
		}
		timer = Date.now();
	};
}

export function throttle (fn, threshhold, scope) {
	threshhold || (threshhold = 250);
	var last,
	deferTimer;
	return function () {
		var context = scope || this;

		var now = +new Date,
		args = arguments;
		if (last && now < last + threshhold) {
			// hold on to it
			clearTimeout(deferTimer);
			deferTimer = setTimeout(function () {
				last = now;
				fn.apply(context, args);
			}, threshhold);
		} else {
			last = now;
			fn.apply(context, args);
		}
	};
}

export function viewport() {
	var e = window, a = 'inner';
	if ( !( 'innerWidth' in window ) )
	{
		a = 'client';
		e = document.documentElement || document.body;
	}
	return { width : e[ a+'Width' ] , height : e[ a+'Height' ] }
}

export function getOffset (element) {
  const offset = ({ top, left }, { offsetTop = 0, offsetLeft = 0, offsetParent }) => {
    const output = { top: top + offsetTop, left: left + offsetLeft }
    return offsetParent ? offset({ ...output }, offsetParent) : output
  }

  return offset({ top: 0, left: 0 }, element)
}

export function getRandomInt(min, max) {
	return Math.floor(Math.random() * (max - min)) + min;
}

export function watchScroll (elem) {
	$(elem).on('scroll', exports.throttle(function(){
		$(elem).trigger('scrolled');
	}, 50));
}

export function getQueryVariable (variable) {
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable){return pair[1];}
	}
	return(false);
}

export function insertAfter(newNode, referenceNode) {
	referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export function loadJSON(url, callback) {
	var xobj = new XMLHttpRequest();
	xobj.overrideMimeType("application/json");
	xobj.open('GET', url, true);
	xobj.onreadystatechange = function () {
		if (xobj.readyState == 4 && xobj.status == "200") {
			callback(xobj.responseText);
		}
	};
	xobj.send(null);
}

export function loadHTML(url, callback) {
	var xobj = new XMLHttpRequest();
	xobj.responseType = 'document';
	xobj.open('GET', url, true);
	xobj.onreadystatechange = function () {
		if (xobj.readyState == 4 && xobj.status == "200") {
			callback(xobj.responseXML);
		}
	};
	xobj.send(null);
}

export function shuffle(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
}

export function truncateString(string, max) {
	if(string.length > max) {
		return `${string.slice(0, max).split(' ').slice(0, -1).join(' ')}<span class="ellipse">&hellip;</span>`;
	} else {
		return string
	}
}

export function hexToRgb(hex) {
	var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, function(m, r, g, b) {
			return r + r + g + g + b + b;
	});

	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
	} : null;
}

export function removeHash() {
	var scrollV, scrollH, loc = window.location;
	if ('pushState' in history)
		history.pushState("", document.title, loc.pathname + loc.search);
	else {
		// Prevent scrolling by storing the page's current scroll offset
		scrollV = document.body.scrollTop;
		scrollH = document.body.scrollLeft;

		loc.hash = "";

		// Restore the scroll offset, should be flicker free
		document.body.scrollTop = scrollV;
		document.body.scrollLeft = scrollH;
	}
}

// support test for event listener options like 'passive' or 'once'
export function eventOptionSupport(option) {
	var supported = false;
	try {
		var opts = Object.defineProperty({}, option, {
			get: function() {
				supported = true;
			}
		});
		window.addEventListener("testPassive", null, opts);
		window.removeEventListener("testPassive", null, opts);
	} catch (e) { supported = false }
	return supported;
}

export function getDocumentScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export function getDocumentScrollLeft() {
  return window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
};

export function unique(value, index, self) {
	return self.indexOf(value) === index;
}

export function pageVisibilityStatus() {
	let hidden, visibilityChange; 
	if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
		hidden = "hidden";
		visibilityChange = "visibilitychange";
	} else if (typeof document.msHidden !== "undefined") {
		hidden = "msHidden";
		visibilityChange = "msvisibilitychange";
	} else if (typeof document.webkitHidden !== "undefined") {
		hidden = "webkitHidden";
		visibilityChange = "webkitvisibilitychange";
	}
	return { hidden, visibilityChange }
}