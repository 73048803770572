
import { viewport } from './toolbox.js';


function init () {
	$('a[href*="#"]:not([href="#"])').click(function(e) {
		let link = this;

		if(link.classList.contains('smooth-ignore') || link.hasAttribute('data-fancybox')) return;

		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {

			var hash = this.hash;
			var target = $(hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			var targetPos, currentPos, delta, dur;
			var offset = (viewport().width < 1050) ? 59 : 69;
			if(target.hasClass('tab-content')) return;
			if (target.length) {

				event.preventDefault();

				$('body, html').removeClass('show-nav');
				target.prop('id', 'target');
				targetPos = target.offset().top - offset;

				location.hash = hash;
				
				window.scroll({ top: targetPos, left: 0, behavior: 'smooth' });
				requestAnimationFrame(() => {
					target.prop('id', hash.split('#')[1]);
				})

			}
		}
	});
}
init();
export default init;